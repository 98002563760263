import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      {/* <Container fluid className="header-image" /> */}
      <Container className="list-page mb-5">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>

        <div className="stage mb-1 mt-3">
          <a href="https://www.bawrs.de/">
            <img
              src={require('./assets/images/bawrs_header.jpg')}
              alt="BAWRS – FATONI | EDGAR WASSER | JUSE JU"
              className="img-fluid home-header-image"
            />
          </a>
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products}
            columnClassName="col-sm-12 col-md-6 col-lg-4"
            filters="off"
            showFormerPrice
            showTags
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
